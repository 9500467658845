import type { TStatistic } from '~/store/statistic/statistic.types';
import type { IWebsocketResponse } from '~/api/global/webosket/websocket.types';
import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';
import { EStatisticItemVariants } from '~/config/apps/index.types';
import { useAlertStore } from '~/store/alert/alert.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';

export const useStatisticStore = defineStore('global/statistic', () => {
  const {
    $api: { websocket, statistic },
  } = useNuxtApp();

  const alertStore = useAlertStore();

  const items = ref<TStatistic>({});

  const isLoaded = ref(false);

  let unsubscribe: () => boolean;

  const websocketCallback = (data: IWebsocketResponse) => {
    const event = data.event as EStatisticItemVariants;
    items.value[event] = isNaN(+data.msg) ? 0 : +data.msg;
  };

  const subscribe = () => {
    unsubscribe = websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: websocketCallback,
      event: Object.values(EStatisticItemVariants),
      fieldsToParse: ['msg'],
      uniqueKey: 'ProjectStatistics/AllStats',
    });
  };

  /** Получить статистику в футере */
  const getStatistic = async () => {
    try {
      const { data } = await statistic.getStatistic<TStatistic>();
      if (!data) return;
      items.value = data;
    } catch (e) {
      alertStore.showError({
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      isLoaded.value = true;
    }
  };

  onMounted(async () => {
    await getStatistic();
    subscribe();
  });
  onBeforeUnmount(() => unsubscribe?.());

  return {
    getStatistic,
    isLoaded,
    items,
  };
});
